import logo from "../assets/signature/smc_logo.jpg";
import * as React from "react";

const SignaturePage = () => {
    return (

       <img src={logo}/>

    );
};

export default SignaturePage;